import { StaticImage } from "gatsby-plugin-image";
import CareerDetail from "../../features/career_detail";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Mobile app developer",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          roles: [
            "Imparerai a costruire un progetto digitale in ogni sua parte",
            "Svilupperai componenti per migliorare l'esperienza di interazione dell'utente",
            "Scriverai buon codice organizzato",
            "Creerai i prodotti digitali del futuro",
            "Farai tanta ricerca!",
          ],
          prerequisites: [
            "Conoscenze di base in programmazione, con un focus particolare su UI.",
            "Padronanza di Flutter e Dart.",
            "Familiarità con le tecnologie di sviluppo native, come Android (Kotlin/Java) e iOS (Swift).",
            "Attitudine alla ricerca e al miglioramento continuo.",
            "Abilità di lavorare in modo collaborativo e indipendente.",
          ],
          requiredTools: [
            {
              name: "Flutter",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/mobileDevFlutter.png"
                  }
                />
              ),
            },
            {
              name: "Dart",
              image: (
                <StaticImage
                  alt=""
                  src={"../../features/career_detail/images/mobileDevDart.png"}
                />
              ),
            },
            {
              name: "React Native",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/mobileDevReactNat.png"
                  }
                />
              ),
            },
          ],
          yearsOfExperience: 0,
        },
      }}
    />
  );
};
